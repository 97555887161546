import { AuthController } from '@controllers/auth-controller';
import { codeSplit } from 'bernie-core';

export class OnboardingController extends AuthController {
  public pageId = 'Onboarding';
  public path = '/onboarding';
  public routeName = 'onboarding';
  public bundles = [];
  public pageTitle = 'Onboarding';

  public component = codeSplit(() => import(/* webpackChunkName: "campaign-management" */ '../views/onboarding'));
  public exact = false;
  public isPartnerProtected = false;
}
