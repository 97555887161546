import { AuthController } from '@controllers/auth-controller';
import { VIEW_AND_MANAGE } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

export class ViewAndManageController extends AuthController {
  public pageId = VIEW_AND_MANAGE;
  public path = '/view-and-manage';
  public routeName = VIEW_AND_MANAGE;
  public bundles = [];
  public pageTitle = VIEW_AND_MANAGE;

  public component = codeSplit(() => import(/* webpackChunkName: "partner-account" */ '../views/view-and-manage'));
  public exact = true;
}
