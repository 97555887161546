import { BillingCenterController } from '@controllers/billing-center-controller';
import { CampaignManagementController } from '@controllers/campaign-management-controller';
import { CampaignWizardController } from '@controllers/campaign-wizard-controller';
import { ErrorController } from '@controllers/error-controller';
import { LandingPageController } from '@controllers/landing-page-controller';
import { LogoutController } from '@controllers/logout-controller';
import { OnboardingController } from '@controllers/onboarding-controller';
import { ReportingController } from '@controllers/reporting-controller';
import { SelectAccountController } from '@controllers/select-account-controller';
import { UsersController } from '@controllers/users-controller';

import { ViewAndManageController } from './view-and-manage';

export const controllers = [
  ErrorController,
  LandingPageController,
  LogoutController,
  SelectAccountController,
  UsersController,
  OnboardingController,
  CampaignWizardController,
  CampaignManagementController,
  BillingCenterController,
  ReportingController,
  ViewAndManageController,
];
