export const BILLING_CENTER = 'Billing Center';
export const CAMPAIGN_MANAGEMENT = 'Campaign Management';
export const CAMPAIGN_WIZARD = 'Campaign Wizard';
export const ERROR = 'Error';
export const REPORTING = 'Reporting';
export const LANDING = 'Landing';
export const LOGOUT = 'Logout';
export const SELECT_ACCOUNT = 'Select Account';
export const USERS = 'Users';
export const VIEW_AND_MANAGE = 'View and Manage';
export const PAYMENT_PROFILE = 'Payment Profile';
