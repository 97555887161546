import { getTraceId } from '@utils/trace-utils';
import axios from 'axios';
import { getConfig } from 'bernie-config';
import { serializeError } from 'serialize-error';
import { JSON_HEADERS, TRACE_ID } from 'src/constants';

const SERVICE_NAME = 'PLATFORM_CONFIGURATION_SYNC';

export interface IConfigData {
  partner_account_configuration: {
    is_onboarded: boolean;
    enabled_ad_types: string[];
  };
}

const getConfigData: (request, principalToken, partnerAccountId) => Promise<IConfigData & Error> = async (
  request,
  principalToken,
  partnerAccountId,
) => {
  const traceId = getTraceId(request);
  request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getConfigData', traceId], { partnerAccountId });

  if (!principalToken || !partnerAccountId) {
    request.log([SERVICE_NAME, 'advertiser-portal-pwa.error.getConfigData', traceId], {
      userId: partnerAccountId,
      message: 'No partner account id or token available',
    });
    throw new Error('Unauthorized');
  }

  const service = getConfig()['services']['platform-configuration-sync'];
  const { protocol, hostname, basePath } = service;

  try {
    const response = await axios({
      method: 'GET',
      url: `${protocol}//${hostname}${basePath}campaign-partner-configuration/${partnerAccountId}`,
      signal: AbortSignal.timeout(5000),
      headers: {
        ...JSON_HEADERS,
        [TRACE_ID]: traceId,
        Authorization: `EGToken Principal-JWT=${principalToken}`,
      },
    });

    return response.data || {};
  } catch (error) {
    request.log([SERVICE_NAME, 'advertiser-portal-pwa.error.getConfigData', traceId], {
      error: serializeError(error),
    });
    return null;
  }
};

export { getConfigData };
